<template>
  <tr :class="'request' + request.id">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + request.id" v-model="isChecked"/>
        <label :for="'checkbox' + request.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2">{{ request.old_username }}</td>
    <td class="p-2">{{ request.new_username }}</td>
    <td class="p-2">{{ request.reason }}</td>
    <td class="p-2 whitespace-nowrap">
      <span :class="'whitespace-nowrap inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full ' + getChangeUsernameRequestStatusClass(request.status)">{{
                getChangeUsernameRequestStatusText(request.status)
              }}</span>
      <span v-if="request.status === 'rejected' && request.reject_reason" class="ml-1"><i class="far fa-question-circle"
                                                                          :uk-tooltip="request.reject_reason"></i></span>
    </td>
    <td class="p-2">{{ timeago(request.created_at) }}</td>
    <td>
      <button v-if="request.status === 'pending'"
              class="px-2 py-1 text-xs rounded-full bg-green-500 text-white mx-2" v-on:click="approve(request)">Đồng
        ý
      </button>
      <button v-if="request.status === 'pending'" href="#reject-request-modal" uk-toggle
              class="px-2 py-1 text-xs rounded-full bg-red-500 text-white" v-on:click="reject(request)">Từ chối
      </button>
    </td>
  </tr>
</template>

<script>

import {
  getChangeUsernameRequestStatusClass,
  getChangeUsernameRequestStatusText,
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminRequestItem",
  props: {
    request: Object
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    getChangeUsernameRequestStatusText(x) {
      return getChangeUsernameRequestStatusText(x);
    },
    getChangeUsernameRequestStatusClass(x) {
      return getChangeUsernameRequestStatusClass(x);
    },
    approve(request) {
      this.$emit("approveRequest", request);
    },
    reject(request) {
      this.$emit("rejectRequest", request, this.reason);
    }
  },
  created() {
    this.isChecked = this.request.is_checked;
  },
  watch: {
    "request.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("checkedRequest", this.request, newVal);
      }
    },
  }
}
</script>
