<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <nav class="responsive-nav -mb-0.5 lg:pl-2">
            <div class="flex justify-between flex-col-reverse lg:flex-row">
              <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent">
                <ul>
                  <li :class="{'active': status === 'pending'}"><a href="#" v-on:click="status = 'pending'">Đang chờ<span>{{ pendingCount }}</span></a></li>
                  <li :class="{'active': status === 'approved'}"><a href="#" v-on:click="status = 'approved'">Đồng ý<span>{{ approvedCount }}</span></a></li>
                  <li :class="{'active': status === 'rejected'}"><a href="#" v-on:click="status = 'rejected'">Từ chối<span>{{ rejectedCount }}</span></a></li>
                </ul>
              </nav>
            </div>
          </nav>

          <form class="mt-4" v-on:submit.prevent="processBatchChangeUsernameRequest">
            <div class="flex space-x-4">
              <input v-model="q" placeholder="Tìm kiếm" type="text" class="form-control shadow-none with-border"/>
              <select v-model="action" class="btn-group bootstrap-select shadow-none with-border">
                <option value="">-- Thao tác --</option>
                <option value="approve">Đồng ý</option>
                <option value="reject">Từ chối</option>
              </select>
              <button :disabled="!selectedRequestIds.length || !action" type="submit" class="hover:bg-gray-300 bg-gray-200 rounded-md whitespace-nowrap px-4">Thực hiện <span v-if="selectedRequestIds.length">({{ selectedRequestIds.length }})</span></button>
            </div>
          </form>

          <div class="mt-4">
            <table class="w-full">
              <thead>
                <tr class="border-b border-slate-200">
                  <th class="flex align-items-center">
                    <div class="checkbox">
                      <input type="checkbox" id="checkbox" v-model="isCheckedAll"/>
                      <label for="checkbox"><span class="checkbox-icon"></span></label>
                    </div>
                  </th>
                  <th class="py-2 pr-2">Username cũ</th>
                  <th class="py-2 pr-2">Username mới</th>
                  <th class="py-2 pr-2">Lý do</th>
                  <th class="py-2 pr-2">Trạng thái</th>
                  <th class="py-2 pr-2">Ngày yêu cầu</th>
                </tr>
              </thead>
              <tbody class="divide-y" v-if="requests.data && requests.data.length">
              <change-username-request-item 
                  v-on:approveRequest="handleApproveRequest" 
                  v-on:rejectRequest="handleRejectRequest"
                  v-on:checkedRequest="handleCheckedRequest" 
                  :request="request"
                  v-for="request in requests.data" 
                  :key="'request' + request.id"/>
              </tbody>
            </table>

            <div class="mt-4">
              <v-pagination
                  class="justify-content-center"
                  v-if="requests.paginatorInfo.lastPage > 1"
                  v-model="requests.paginatorInfo.currentPage"
                  :pages="requests.paginatorInfo.lastPage"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="loadRequests"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="reject-request-modal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Từ chối yêu cầu đổi username</h3>
          <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2">
          <textarea v-model="reason" class="shadow-none with-border" placeholder="Lý do từ chối" id="reason"></textarea>
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a href="javascript:void(0)" v-on:click="rejectRequest"
             class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
            Từ chối</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import ChangeUsernameRequestItem from "./ChangeUsernameRequestItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {canManageComment, changePageTitle} from "../../../core/services/utils.service";

export default {
  name: "AdminCommentList",
  components: {ChangeUsernameRequestItem, VPagination},
  data() {
    return {
      selectedRequestIds: [],
      q: "",
      action: "",
      status: "pending",
      isCheckedAll: false,
      requests: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      pendingCount: 0,
      approvedCount: 0,
      rejectedCount: 0,
      currentRequest: null,
      reason: ""
    }
  },
  methods: {
    loadRequests() {
      let where = {
        AND: [
          {column: "status", value: this.status}
        ]
      }

      if (this.q) {
        where.AND.push({column: "old_username", value: this.q + "%", operator: "LIKE"});
      }

      let query = `query($page: Int, $where: WhereConditions) {
        changeUsernameRequests(first:10, where: $where, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            old_username
            new_username
            reason
            reject_reason
            created_at
            status
            user {
              id
              username
              avatar {
                url
              }
            }
          }
          paginatorInfo {
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query, {where: where, page: this.requests.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.changeUsernameRequests) {
              this.requests = data.data.changeUsernameRequests;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadPendingCount() {
      let query = `query {
        changeUsernameRequests(first:1, where: {AND: [{column: "status", value: "pending"}]}) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.changeUsernameRequests && data.data.changeUsernameRequests.paginatorInfo) {
              this.pendingCount = data.data.changeUsernameRequests.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadApprovedCount() {
      let query = `query {
        changeUsernameRequests(first:1, where: {AND: [{column: "status", value: "approved"}]}) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.changeUsernameRequests && data.data.changeUsernameRequests.paginatorInfo) {
              this.approvedCount = data.data.changeUsernameRequests.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadRejectedCount() {
      let query = `query {
        changeUsernameRequests(first:1, where: {AND: [{column: "status", value: "rejected"}]}) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.changeUsernameRequests && data.data.changeUsernameRequests.paginatorInfo) {
              this.rejectedCount = data.data.changeUsernameRequests.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    approveRequest() {
      if (confirm("Bạn có chắc muốn đồng ý yêu cầu đổi username này?")) {
        this.processChangeUsernameRequest(this.currentRequest, "approve");
      }
    },
    rejectRequest() {
      this.processChangeUsernameRequest(this.currentRequest, "reject", this.reason);
    },
    processChangeUsernameRequest(request, action, reason) {
      let query = `mutation($id: ID!, $action: String!, $reason: String) {
        processChangeUsernameRequest(id: $id, action: $action, reason: $reason) {
          id
          status
        }
      }`;

      ApiService.graphql(query, {id: request.id, action: action, reason: reason})
          .then(({data}) => {
            if (data.data && data.data.processChangeUsernameRequest) {
              this.reRender();
              if (action === 'reject') {
                this.reason = "";
                window.$(".uk-modal-close-default").click();
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    processBatchChangeUsernameRequest() {
      if (!this.selectedRequestIds.length || !this.action) {
        return;
      }

      if (confirm("Bạn có chắc muốn thực hiện thao tác này?")) {
        let query = `mutation($ids: [ID!], $action: String!) {
          processBatchChangeUsernameRequest(ids: $ids, action: $action)
        }`;
        
        ApiService.graphql(query, {ids: this.selectedRequestIds, action: this.action})
            .then(({data}) => {
              if (data.data && data.data.processBatchChangeUsernameRequest) {
                this.selectedRequestIds = [];
                this.reRender();
              } else {
                alert(data.errors[0].message);
              }
            })
            .catch((response) => {
              alert(response.message);
            });
      }
    },
    handleApproveRequest(request) {
      this.currentRequest = request;
      this.approveRequest();
    },
    handleRejectRequest(request) {
      this.currentRequest = request;
    },
    reRender() {
      this.loadRequests();
      this.loadPendingCount();
      this.loadApprovedCount();
      this.loadRejectedCount();
    }
  },
  mounted() {
    if (!canManageComment()) {
      this.$router.push({name: "Home"});
      return;
    }
    this.reRender();
    changePageTitle("Quản lý yêu cầu thay đổi username");
  },
  watch: {
    q: {
      handler() {
        this.requests.paginatorInfo.currentPage = 1;
        this.loadRequests();
      }
    },
    status: {
      handler() {
        this.requests.paginatorInfo.currentPage = 1;
        this.loadRequests();
      }
    },
    isCheckedAll: {
      handler(newVal) {
        this.requests.data.map((request) => {
          request.is_checked = newVal;
        });
      }
    },
    "requests.data": {
      handler(newVal) {
        this.selectedRequestIds = [];
        newVal.map((request) => {
          if (request.is_checked) {
            this.selectedRequestIds.push(request.id);
          }
        });
      },
      deep: true
    },
  }
}
</script>
